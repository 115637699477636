.VRExperiments{
    align-items: center;
    margin: auto;
    width: auto;
    height: auto;
}

.carousel-inner img {
    width: 100%;
    margin: auto;
}
