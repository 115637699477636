.App{
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.header {
  flex: 0 0 10%;
  color: white;
}

.body{
  flex: 0 0 90%;
  padding: 15px;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
